.p-image {
    position: absolute;
    top: auto;
    right: 8px;
    bottom: 10px;
    transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    background: #3f3fd9;
    color: white;
    border-radius: 50%;
    height: 30px;
    width: 30px;
    line-height: 28px;
    text-align: center;
    font-size: 12px;
    cursor: pointer;
}
.profile-pic {
    max-width: 100%;
    width: 150px;
    height: 150px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    display: block;
    border-radius: 50%;
    object-fit: cover;
    -webkit-border-radius: 50%;
}
.profile-img-edit {
    position: relative;
    display: inline-block;
    height: 150px;
    width: 150px;
}
.close-image {
    position: absolute;
    top: 10px;
    right: 2px;
    border: white;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    line-height: 10px;
    text-align: center;
    cursor: pointer;
}